.img-skeleton {
  width: 186px !important;
  height: 280px !important;
  background-color: var(--d-neutral-card-2, #f2f4f7) !important;
  border-radius: 4px !important;
}
.install-modal {
  font-family: "Lato", sans-serif;
  .ant-modal-content {
    padding: 40px;
    border-radius: 16px;
    background-color: var(--d-neutral-bg1);
  }
  .ant-modal-close {
    width: 40px;
    height: 40px;
    top: 32px;
    right: 30px;
    color: var(--d-neutral-foot);
    svg {
      width: 2em;
      height: 1em;
      fill: currentColor;
    }
    svg path {
      stroke: currentColor;
      stroke-width: 3;
    }
    &:hover {
      border-radius: 8px;
      background-color: var(--d-neutral-card2);
    }
  }
  .ant-modal-confirm-title {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    color: var(--d-neutral-title1);
  }

  .ant-modal-confirm-content {
    margin: 40px auto;
    padding: 40px;

    background-color: var(--d-neutral-card3);
    color: var(--d-neutral-title1);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    font-size: 17px;
    line-height: 20.4px;
    font-weight: 600;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    font-weight: 600;
  }
  .ant-modal-confirm-body-wrapper {
    .button {
      margin: 0 auto;
    }
  }

  .install {
    display: flex;
    flex-direction: column;
    .section1 {
      display: flex;
      align-items: center;
      gap: 8px;
      .rabby-brand {
        display: flex;
        align-items: center;
        gap: 6px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .btn {
      margin-top: 32px;
      width: 280px;
      height: 56px;
      border-radius: 8px;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-content: center;
      gap: 8px;

      background-color: var(--d-blue-default);
      color: #ffffff;
      line-height: 56px;

      &:hover {
        box-shadow: 0px 4px 16px 0px #7084ff80;
      }
    }
  }
  .tour {
    .pictures {
      margin-top: 32px;
      display: flex;
      gap: 20px;
      img {
        width: 186px;
        height: 280px;
        border-radius: 4px;
      }
    }
  }
}
