* {
  word-break: keep-all;
  padding: 0;
  margin: 0;
}
body {
  overflow-x: hidden;
  zoom: 0.8;
}
.dbk-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--d-neutral-bg1);
  & > * {
    width: 100%;
    & > .nav {
      width: 96%;
      margin: 0 auto;
    }
    & > div, .nav {
      header {
        color: var(--d-neutral-title1);
      }
      main {
        color: var(--d-neutral-foot);
      }
    }
  }
}
.header {
  background: url("./assets/road.png");
  width: 100%;
  height: 1000px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: var(--d-neutral-title2);
  white-space: nowrap;
}

#bridge {
  height: 640px;
}

#mint {
  width: 100%;
  background: linear-gradient(
    132.94deg,
    rgba(42, 187, 127, 0.05) 5.01%,
    rgba(254, 105, 73, 0.05) 95.5%
  );
  height: 640px;
}
.partner > div {
  margin: 80px auto;
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  header {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
  }
  main {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    section {
      width: 400px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 28px;
      font-weight: 700;
      line-height: 33.6px;
      position: relative;
      .hover-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        position: relative;
        &:hover {
          padding: 12px 28px;
          border-radius: 900px;
          background-color: var(--d-neutral-card3);
        }
      }

      color: var(--d-neutral-title1);

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
      }
      &:last-child {
        background: linear-gradient(132.94deg, #2abb7f 5.01%, #fe6949 95.5%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
        position: relative;
        cursor: pointer;
        &:hover {
          &::before {
            content: "";
            position: absolute;
            width: 272px;
            height: 58px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 999px;
            background: linear-gradient(
              132.94deg,
              rgba(42, 187, 127, 0.1) 5.01%,
              rgba(254, 105, 73, 0.1) 95.5%
            );
          }
        }
      }
    }
  }
}
.brand {
  border-top: 0.5px solid var(--d-neutral-line);
  width: 100%;
  & > div {
    max-width: 1200px;
    margin: 0 auto;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tips {
  .ant-message-notice-content {
    background-color: var(--d-neutral-black) !important;
    padding: 8px 16px !important;
    border-radius: 2px !important;
    color: var(--d-neutral-title2);
    .anticon-info-circle {
      display: none;
    }
  }
}

@media screen and (max-width: 800px) {
  .header {
    height: 339px;
    header {
      height: 44px;
      gap: 0;
      justify-content: space-around;
      align-items: center;
      .brandContainer {
        gap: 7px;
        width: 91px;
        .icon {
          width: 20.6px;
          height: 20.6px;
        }
        .name {
          font-size: 13px;
        }
      }
      .actions {
        font-size: 13px;
        flex: 1;
        overflow-x: auto;
        white-space: nowrap;
        & > * {
          padding: 4px 10px;
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .link {
        width: 28px;
        margin-left: unset;
        img {
          width: 16px;
          height: 14px;
        }
      }
    }
    main {
      text-align: center;
      .title {
        margin-top: 30px;
        font-size: 44px;
        font-weight: 900;
        line-height: 52.8px;
      }
      .desc {
        margin-top: 16px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
      .btn {
        margin-top: 53px;
        .button {
          width: 220px !important;
          height: 44px !important;
          font-size: 17px !important;
          font-weight: 400;
          line-height: 20.4px;
          margin: 0 auto;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  #bridge {
    height: unset;
    padding: 40px 16px;
    & > div {
      flex-direction: column-reverse;
      header {
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: center;
      }
      main {
        margin-top: 16px;
        font-size: 15px;
        font-weight: 400;
        line-height: 19.5px;
        text-align: center;
      }
      .btn {
        margin-top: 32px;
        .button {
          width: 220px !important;
          height: 44px !important;
          font-size: 17px !important;
          font-weight: 400;
          line-height: 20.4px;
          margin: 0 auto;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      img {
        width: 100%;
        margin-top: 40px;
      }
    }
  }
  #mint {
    height: unset;
    padding: 40px 16px;
    & > div {
      flex-direction: column;
      header {
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: center;
      }
      main {
        margin-top: 16px;
        font-size: 15px;
        font-weight: 400;
        line-height: 19.5px;
        text-align: center;
      }
      .btn {
        margin-top: 32px;
        .button {
          width: 220px !important;
          height: 44px !important;
          font-size: 17px !important;
          font-weight: 400;
          line-height: 20.4px;
          margin: 0 auto;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      div:has(img) {
        width: 100%;
        height: max-content;
      }
      img {
        width: 81.4%;
        margin-top: 40px;
      }
    }
  }
  .partner {
    & > div {
      margin: 40px auto;
    }
    header {
      font-size: 24px !important;
      font-weight: 700 !important;
      line-height: 28.8px !important;
      text-align: center;
    }
    main {
      margin-top: 24px !important;
      flex-direction: column;
      align-items: center;
      section {
        width: 100% !important;
        height: 60px !important;
        gap: 8px !important;

        img {
          width: 24px !important;
          height: 24px !important;
        }
        div {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          text-align: center;
        }
        &:last-child {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
  .brand {
    padding: 24px 0 25px;
    .brand-footer {
      flex-direction: column;
      align-items: center;
      gap: 17px;
      div {
        text-align: center;
      }
    }
  }
  .link {
    transform: translateX(0px);
  }
}
