.actions {
  font-size: 18px;
  line-height: 21.6px;
  color: var(--d-neutral-title2);
  display: flex;
  & > * {
    padding: 9px 24px;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
    }
  }
}
.link {
  padding: 8px;
  margin-left: auto;
  transform: translateX(-33px);
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
  img {
    width: 20px; height: 20px;
  }
}