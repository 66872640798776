:root {
  /* -------------------- base define -------------------- */
  --d-blue-default: rgba(112, 132, 255, 1);
  --d-blue-light1: rgba(238, 241, 255, 1);
  --d-blue-light-1: rgba(238, 241, 255, 1);
  --d-blue-light2: rgba(222, 227, 252, 1);
  --d-blue-light-2: rgba(222, 227, 252, 1);
  --d-blue-disable: rgba(219, 224, 255, 1);

  --d-green-default: rgba(42, 187, 127, 1);
  --d-green-light: rgba(216, 242, 231, 1);

  --d-orange-default: rgba(255, 176, 32, 1);
  --d-orange-light: rgba(255, 245, 226, 1);

  --d-red-default: rgba(227, 73, 53, 1);
  --d-red-light: rgba(255, 242, 240, 1);
  --d-red-dark: rgba(174, 42, 25, 1);
  --d-red-light2: rgba(239, 212, 209, 1);
  --d-red-light-2: rgba(239, 212, 209, 1);

  --d-neutral-title1: rgba(25, 41, 69, 1);
  --d-neutral-title-1: rgba(25, 41, 69, 1);
  --d-neutral-body: rgba(62, 73, 94, 1);
  --d-neutral-foot: rgba(106, 117, 135, 1);
  --d-neutral-line: rgba(211, 216, 224, 1);

  --d-neutral-bg1: rgba(255, 255, 255, 1);
  --d-neutral-bg-1: rgba(255, 255, 255, 1);
  --d-neutral-bg2: rgba(242, 244, 247, 1);
  --d-neutral-bg-2: rgba(242, 244, 247, 1);
  --d-neutral-bg3: rgba(247, 250, 252, 1);
  --d-neutral-bg-3: rgba(247, 250, 252, 1);
  --d-neutral-bg4: rgba(235, 238, 247, 1);

  --d-neutral-card1: rgba(255, 255, 255, 1);
  --d-neutral-card-1: rgba(255, 255, 255, 1);
  --d-neutral-card2: rgba(242, 244, 247, 1);
  --d-neutral-card-2: rgba(242, 244, 247, 1);
  --d-neutral-card3: rgba(247, 250, 252, 1);
  --d-neutral-card-3: rgba(247, 250, 252, 1);
  
  --d-neutral-title2: rgba(255, 255, 255, 1);
  --d-neutral-title-2: rgba(255, 255, 255, 1);

  --d-neutral-black: rgba(0, 0, 0, 1);
}

@media (prefers-color-scheme: dark) {
  :root {
    --d-blue-default: rgba(112, 132, 255, 1);
    --d-blue-light1: rgba(66, 73, 98, 1);
    --d-blue-light-1: rgba(66, 73, 98, 1);
    --d-blue-light2: rgba(66, 73, 98, 1);
    --d-blue-light-2: rgba(66, 73, 98, 1);
    --d-blue-disable: rgba(76, 86, 133, 1);
    
    --d-green-default: rgba(42, 187, 127, 1);
    --d-green-light: rgba(57, 90, 90, 1);

    --d-orange-default: rgba(255, 198, 74, 1);
    --d-orange-light: rgba(72, 67, 57, 1);

    --d-red-default: rgba(239, 92, 72, 1);
    --d-red-light: rgba(79, 58, 66, 1);
    --d-red-dark: rgba(174, 42, 25, 1);
    --d-red-light2: rgba(79, 58, 66, 1);
    --d-red-light-2: rgba(79, 58, 66, 1);

    --d-neutral-title1: rgba(247, 250, 252, 1);
    --d-neutral-title-1: rgba(247, 250, 252, 1);
    --d-neutral-body: rgba(211, 216, 224, 1);
    --d-neutral-foot: rgba(186, 190, 197, 1);
    --d-neutral-line: rgba(255, 255, 255, 0.1);

    --d-neutral-bg1: rgba(28, 31, 43, 1);
    --d-neutral-bg-1: rgba(28, 31, 43, 1);
    --d-neutral-bg2: rgba(28, 31, 43, 1);
    --d-neutral-bg-2: rgba(28, 31, 43, 1);
    --d-neutral-bg3: rgba(28, 31, 43, 1);
    --d-neutral-bg-3: rgba(28, 31, 43, 1);
    --d-neutral-bg-4: rgba(32, 36, 54, 1);

    --d-neutral-card1: rgba(255, 255, 255, 0.06);
    --d-neutral-card-1: rgba(255, 255, 255, 0.06);
    --d-neutral-card2: rgba(255, 255, 255, 0.06);
    --d-neutral-card-2: rgba(255, 255, 255, 0.06);
    --d-neutral-card3: rgba(255, 255, 255, 0.06);
    --d-neutral-card-3: rgba(255, 255, 255, 0.06);

    --d-neutral-title2: rgba(255, 255, 255, 1);
    --d-neutral-title-2: rgba(255, 255, 255, 1);

    --d-neutral-black: rgba(0, 0, 0, 1);
  }
}
