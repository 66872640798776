.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #fff;
  text-align: center;
  background: linear-gradient(93.86deg, #ffab6e 7.81%, #fe6e4f 93.56%);
  border: 2px solid #fff;
  border-radius: 9999px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 4px 16px 0px #FE6F5080;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
