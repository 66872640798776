.brandContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  .icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
  }
  .name {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700px;
  }
}